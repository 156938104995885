<template>
  <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
    <el-table v-loading="loading" :data="exportRecord.dataList">
      <el-table-column label="导出账户" align="center" prop="exporterName" />
      <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="文件名" align="center" prop="fileName" />
      <el-table-column label="导出状态" align="center" prop="exportState"/>
      <el-table-column label="错误原因" align="center" prop="failReason"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-link :href="scope.row.exportPath">下载</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getExportList"
    />
  </el-dialog>
</template>

<script>
import {parseTime} from "@/utils/untilsMethod";
import {exportList} from "@/api/exportFile";
export default {
  name: 'ExportRecordDialog',
  props: {
    // 导出记录弹窗配置对象
    exportRecord: {
      type: Object,
      default: () => ({
        open: false,
        title: '导出记录',
        dataList: []
      })
    },
    // 总条数
    total: {
      type: Number,
      default: 0
    },
    // 查询参数对象
    queryParams: {
      type: Object,
      default: () => ({
        exportType: 21,
        page: 1,
        size: 20
      })
    },

  },
  data() {
    return {
      loading: false,
      dialogVisible: this.visible
    }
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal
    }
  },
  methods: {
    parseTime,
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
  }
}
</script>
